export const onClientEntry = () => {
    if (process.env.ENABLE_LOCAL_SW) {
      registerServiceWorker();
    }
};
  
export const registerServiceWorker = () => {
    if (typeof window !== 'undefined') {
      // Do magic
    }
};

export const onInitialClientRender = () => {
  setTimeout(function() {
      document.getElementById("___loader").style.display = "none"
  }, 100)
};